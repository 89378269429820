import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117')
];

export const server_loads = [10,2,4];

export const dictionary = {
		"/admin": [68,[10],[11]],
		"/admin/(auth)/agency": [69,[10,12],[11]],
		"/admin/auth/login": [117,[10,13],[11]],
		"/admin/(auth)/billing/fallback-settings": [70,[10,12],[11]],
		"/admin/(auth)/config/billing-settings": [71,[10,12],[11]],
		"/admin/(auth)/conversations": [72,[10,12],[11]],
		"/admin/(auth)/domains": [73,[10,12],[11]],
		"/admin/(auth)/logs": [74,[10,12],[11]],
		"/admin/(auth)/messages": [75,[10,12],[11]],
		"/admin/(auth)/operator/paymentsReport": [77,[10,12],[11]],
		"/admin/(auth)/operator/payment": [76,[10,12],[11]],
		"/admin/(auth)/operator/payoutDetails": [78,[10,12],[11]],
		"/admin/(auth)/operator/payoutManage": [79,[10,12],[11]],
		"/admin/(auth)/operator/rating-bonus": [80,[10,12],[11]],
		"/admin/(auth)/operator/refund": [81,[10,12],[11]],
		"/admin/(auth)/payments-groups": [83,[10,12],[11]],
		"/admin/(auth)/payments": [82,[10,12],[11]],
		"/admin/(auth)/permissions": [84,[10,12],[11]],
		"/admin/(auth)/permissions/settings": [86,[10,12],[11]],
		"/admin/(auth)/permissions/[id]": [85,[10,12],[11]],
		"/admin/(auth)/product/subscription": [87,[10,12],[11]],
		"/admin/(auth)/product/time-package": [88,[10,12],[11]],
		"/admin/(auth)/refunds": [89,[10,12],[11]],
		"/admin/(auth)/reports": [90,[10,12],[11]],
		"/admin/(auth)/rooms": [91,[10,12],[11]],
		"/admin/(auth)/rooms/alerts": [93,[10,12],[11]],
		"/admin/(auth)/rooms/alerts/settings": [94,[10,12],[11]],
		"/admin/(auth)/rooms/[id]": [92,[10,12],[11]],
		"/admin/(auth)/stats/ad": [95,[10,12],[11]],
		"/admin/(auth)/story": [96,[10,12],[11]],
		"/admin/(auth)/story/settings": [97,[10,12],[11]],
		"/admin/(auth)/terms": [98,[10,12],[11]],
		"/admin/(auth)/users": [99,[10,12],[11]],
		"/admin/(auth)/users/rating": [103,[10,12],[11]],
		"/admin/(auth)/users/referrals": [104,[10,12],[11]],
		"/admin/(auth)/users/referrals/details": [105,[10,12],[11]],
		"/admin/(auth)/users/subscriptions": [106,[10,12],[11]],
		"/admin/(auth)/users/time-charges": [107,[10,12],[11]],
		"/admin/(auth)/users/time-package": [108,[10,12],[11]],
		"/admin/(auth)/users/verifications": [109,[10,12],[11]],
		"/admin/(auth)/users/verifications/age": [110,[10,12],[11]],
		"/admin/(auth)/users/verifications/history": [111,[10,12],[11]],
		"/admin/(auth)/users/[id]": [100,[10,12],[11]],
		"/admin/(auth)/users/[id]/moderation": [101,[10,12],[11]],
		"/admin/(auth)/users/[id]/reports": [102,[10,12],[11]],
		"/admin/(auth)/utils/billing-payment-methods": [112,[10,12],[11]],
		"/admin/(auth)/utils/billing-report": [113,[10,12],[11]],
		"/admin/(auth)/utils/experiments": [114,[10,12],[11]],
		"/admin/(auth)/utils/feature-toggles": [115,[10,12],[11]],
		"/admin/(auth)/utils/time-packages-by-countries": [116,[10,12],[11]],
		"/(main)/[[lang=lang]]/(auth)/block": [19,[2,4],[3]],
		"/(main)/[[lang=lang]]/(auth)/chat-history": [20,[2,4],[3]],
		"/(main)/[[lang=lang]]/(auth)/favorites": [21,[2,4],[3]],
		"/(main)/[[lang=lang]]/(public)/healthz": [~51,[2],[3]],
		"/(main)/[[lang=lang]]/(public)/id[refCode]": [52,[2],[3]],
		"/(main)/[[lang=lang]]/(public)/(info)/info/2257": [47,[2,9],[3]],
		"/(main)/[[lang=lang]]/(public)/login": [53,[2],[3]],
		"/(main)/[[lang=lang]]/(public)/login/[oauthMethod]/failure": [54,[2],[3]],
		"/(main)/[[lang=lang]]/(public)/login/[oauthMethod]/verify": [55,[2],[3]],
		"/(main)/[[lang=lang]]/(public)/mail-subscription/unsubscribe": [56,[2],[3]],
		"/(main)/[[lang=lang]]/(auth)/(messages)/messages/[id]": [14,[2,4,5],[3]],
		"/(main)/[[lang=lang]]/(auth)/(messages)/my/messages": [15,[2,4,5],[3]],
		"/(main)/[[lang=lang]]/(auth)/(payment)/payment-failed": [~16,[2,4],[3]],
		"/(main)/[[lang=lang]]/(auth)/(payment)/payment-failure": [17,[2,4],[3]],
		"/(main)/[[lang=lang]]/(auth)/(payment)/payment-success": [18,[2,4],[3]],
		"/(main)/[[lang=lang]]/(public)/(info)/privacy": [48,[2,9],[3]],
		"/(main)/[[lang=lang]]/(auth)/profile": [22,[2,4,6],[3]],
		"/(main)/[[lang=lang]]/(auth)/profile/change-password": [23,[2,4,6],[3]],
		"/(main)/[[lang=lang]]/(auth)/profile/deactivate": [24,[2,4,6],[3]],
		"/(main)/[[lang=lang]]/(auth)/profile/payment-details": [25,[2,4,6],[3]],
		"/(main)/[[lang=lang]]/(auth)/profile/subscription": [26,[2,4,6],[3]],
		"/(main)/[[lang=lang]]/(public)/promo": [57,[2],[3]],
		"/(main)/[[lang=lang]]/(auth)/referral": [27,[2,4],[3]],
		"/(main)/[[lang=lang]]/(public)/(info)/report-content": [~49,[2,9],[3]],
		"/(main)/[[lang=lang]]/(public)/reset-password": [~58,[2],[3]],
		"/(main)/[[lang=lang]]/(public)/reset-password/expired": [59,[2],[3]],
		"/(main)/[[lang=lang]]/(public)/reset-password/success": [60,[2],[3]],
		"/(main)/[[lang=lang]]/(auth)/room/call": [29,[2,4,7],[3]],
		"/(main)/[[lang=lang]]/(auth)/room/call/no-answer": [30,[2,4,7],[3]],
		"/(main)/[[lang=lang]]/(auth)/room/search": [31,[2,4,7],[3]],
		"/(main)/[[lang=lang]]/(auth)/room/settings": [32,[2,4],[3]],
		"/(main)/[[lang=lang]]/(auth)/room/start": [33,[2,4,7],[3]],
		"/(main)/[[lang=lang]]/(auth)/room/[id]": [28,[2,4,7],[3]],
		"/(main)/[[lang=lang]]/(public)/sentry-example": [61,[2],[3]],
		"/(main)/[[lang=lang]]/(auth)/session-exists": [34,[2,4],[3]],
		"/(main)/[[lang=lang]]/(public)/signup": [62,[2],[3]],
		"/(main)/[[lang=lang]]/(auth)/signup/step1": [36,[2,4,8],[3]],
		"/(main)/[[lang=lang]]/(auth)/signup/step2": [37,[2,4,8],[3]],
		"/(main)/[[lang=lang]]/(auth)/signup/step3": [38,[2,4,8],[3]],
		"/(main)/[[lang=lang]]/(auth)/signup/step4": [39,[2,4,8],[3]],
		"/(main)/[[lang=lang]]/(auth)/signup/step5": [40,[2,4,8],[3]],
		"/(main)/[[lang=lang]]/(auth)/signup/step6": [41,[2,4,8],[3]],
		"/(main)/[[lang=lang]]/(auth)/signup/step7": [42,[2,4,8],[3]],
		"/(main)/[[lang=lang]]/(public)/signup/verify": [~64,[2],[3]],
		"/(main)/[[lang=lang]]/(public)/signup/[oauthMethod]/failure": [63,[2],[3]],
		"/(main)/[[lang=lang]]/(auth)/signup/[oauthMethod]/success": [35,[2,4,8],[3]],
		"/(main)/[[lang=lang]]/(public)/start": [65,[2],[3]],
		"/(main)/[[lang=lang]]/(auth)/story/edit": [43,[2,4],[3]],
		"/(main)/[[lang=lang]]/(auth)/story/upload": [44,[2,4],[3]],
		"/(main)/[[lang=lang]]/(auth)/subscription/buy": [45,[2,4],[3]],
		"/(main)/[[lang=lang]]/(public)/(info)/terms": [50,[2,9],[3]],
		"/(main)/[[lang=lang]]/(public)/unsupported-device": [66,[2],[3]],
		"/(main)/[[lang=lang]]/(public)": [46,[2],[3]],
		"/(main)/[[lang=lang]]/[...path]": [67,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,

	reroute: (() => {})
};

export { default as root } from '../root.svelte';